import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Layout from "../../../components/Layout";
import useChangePage from "../../../hooks/useChangePage";
import { fonts } from "../../../theme/fonts";
import { colors } from "../../../theme/colors";
import InfoLabelGroup, { Label } from "../../../components/InfoLabelGroup";
import ImageSlide from "../../../components/ImageSlide";
import AccessImage from "../../../assets/img/access-image.png";
import SlideHeritageCard from "../../../components/SlideHeritageCard";
import ContentLayout from "../../../components/ContentLayout";
import SlideWidthHeader from "../../../components/SlideWidthHeader";
import NGraph from "../../../components/NGraph";
import WordCloudWithInfo from "../../../components/WordCloudWithInfo";
import { useParams } from "react-router-dom";
import { DataSet, Node, Edge } from "vis-network/standalone";
import { useMutation, useQuery } from "react-query";
import {
  requestHeritageDetail,
  requestHeritageGraphList,
  requestHeritageRelatedList,
  requestHeritageWordCloudLists,
} from "@apis/heritage";
import {
  IHeritageRelatedItem,
  IResponseHeritageAsstesDetail,
} from "@apis/heritage/interface/response";
import {
  getDistance,
  getGeoJSONStrokeColor,
  getImage,
  handlePlainCultureDetailInfomation,
  handlePlainCultureWordCloud,
  nullCheck,
} from "utils";
import { WordCloudOption } from "@components/WordCloud";
import _, { debounce } from "lodash";
import ImageModal from "@components/Modal/ImageModal";
import {
  requestAirLineMarkers,
  requestGeometries,
  requestHighDetailGeoMetries,
  reuqestUnionGeometries,
} from "@apis/geometry";
import IconImageHiresOff from "@assets/img/icon/imageHires_off.png";
import IconImageHiresOn from "@assets/img/icon/imageHires_on.png";
import IconModel3DOff from "@assets/img/icon/model3D_off.png";
import IconModel3DOn from "@assets/img/icon/model3D_on.png";
import IconPcdOff from "@assets/img/icon/pcd_off.png";
import IconPcdOn from "@assets/img/icon/pcd_on.png";
import IconVr2DOff from "@assets/img/icon/vr2D_off.png";
import IconVr2DOn from "@assets/img/icon/vr2D_on.png";
import IconVr3DOff from "@assets/img/icon/vr3D_off.png";
import IconVr3DOn from "@assets/img/icon/vr3D_on.png";
import {
  InfoWindowF,
  Marker,
  MarkerF,
  useJsApiLoader,
} from "@react-google-maps/api";
import GoogleMapTypeController, {
  TStringType,
  stringTypes,
} from "@components/GoogleMapTypeController";
import CustomGoogleMap from "@components/CustomGoogleMap";
import MapTooltip, { IMapTooltipRef } from "@components/MapTooltip";
import { IAirLineMarkersResponse } from "@apis/geometry/response";

interface Props {}

interface ILatLng {
  lat: number;
  lng: number;
}

const HeritageDetail: React.FC<Props> = () => {
  const { handleChangePage } = useChangePage();
  /** 구글 맵 */
  const tooltipRef = useRef<IMapTooltipRef>(null);
  const googleStringMapTypeRef = useRef<TStringType>(stringTypes.DEFAULT);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAEyMRIBQFROvVDsxpQPw_u33RSbQcpehs",
    language: "ko",
  });

  let { id } = useParams();
  /** 문화유산 상세 정보 */
  const [cultureDetail, setCultureDetail] =
    useState<IResponseHeritageAsstesDetail | null>(null);
  /** 문화유산 상세 정보 내 Form Infomation */
  const [cultureDetailInfo, setCultureDetailInfo] = useState<Label[]>();
  /** 관련자료 */
  const [relatedList, setRelatedList] = useState<IHeritageRelatedItem[]>([]);
  /** Word Cloud Data */
  const [wordCloudData, setWordCloudData] = useState<WordCloudOption[]>([]);
  /** Map GeoJson 상세내용 Tooltip */

  /** 항공지도 마커 리스트 */
  const [airlineMarkers, setAirlineMarkers] = useState<
    IAirLineMarkersResponse[]
  >([]);

  const [mapLocationInfo, setMapLocationInfo] = useState<ILatLng>();
  const [currentLocation, setCurrentLocation] = useState<ILatLng>({
    lat: 0,
    lng: 0,
  });

  const [currentAllLocation, setCurrentAllLocation] = useState<ILatLng>({
    lat: 0,
    lng: 0,
  });

  /** Google Map Migration */
  const [googleMapZoom, setGoogleMapZoom] = useState<number>(10);
  const googleMapZoomRef = useRef<number>(10);
  // const [map, setMap] = React.useState<google.maps.Map | null>(null);
  const googleMapRef = useRef<google.maps.Map | null>(null);
  const [googleMapType, setGoogleMapType] = useState<string>("terrain");
  const [googleMapStringType, setGoogleStringMapType] = useState<TStringType>(
    stringTypes.DEFAULT
  );

  const onUnmount = React.useCallback(function callback(map: google.maps.Map) {
    googleMapRef.current = null;
  }, []);

  /** 문화유산 상세 조회 API */
  useQuery(
    ["requestHeritageDetailPage", id],
    () => requestHeritageDetail({ id: id || "" }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const { row } = data;
        setCultureDetail(row);
        setCurrentImage(row?.images?.[0] || "");
        setCultureDetailInfo(handlePlainCultureDetailInfomation(row));
        if (row.lat && row.lng) {
          setMapLocationInfo({
            lat: Number(row.lat),
            lng: Number(row.lng),
          });
          setCurrentAllLocation({
            lat: Number(row.lat),
            lng: Number(row.lng),
          });
        }
      },
    }
  );

  /** 3D VisualRaize 관계도 API */
  useQuery(
    ["requestHeritageGraphLists", id],
    () => requestHeritageGraphList({ id: id || "" }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const { nodes, edges } = data;
        setNodes(nodes);
        setEdges(edges);
      },
    }
  );

  /** Word Cloud API */
  useQuery(
    ["requestHeritageWordCloudLists", id],
    () => requestHeritageWordCloudLists({ id: id || "" }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const { rows } = data;
        setWordCloudData(handlePlainCultureWordCloud(rows));
      },
    }
  );

  /** 관련 자료 */
  useQuery(
    ["requestHeritageRelatedList", id],
    () => requestHeritageRelatedList({ id: id || "" }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const { rows } = data;
        setRelatedList(rows);
      },
    }
  );

  /** union Geometries */
  const { mutate: getUnionGeometries } = useMutation(reuqestUnionGeometries, {
    onSuccess: (data) => {
      const { features } = data;
      const geoJson = {
        type: "FeatureCollection",
        features,
      };
      googleMapRef.current?.data.revertStyle();
      googleMapRef.current?.data.forEach((item) => {
        if (item.getProperty("geojsonType") === "union") {
          googleMapRef.current?.data?.remove(item);
        }
      });
      if (googleStringMapTypeRef.current === stringTypes.CADASTRAL) {
        googleMapRef.current?.data.addGeoJson(geoJson);
      }
    },
  });

  const { mutate: getHighDetailGeometries } = useMutation(
    requestHighDetailGeoMetries,
    {
      onSuccess: (data) => {
        const { features } = data;
        const geoJson = {
          type: "FeatureCollection",
          features,
        };
        googleMapRef.current?.data.revertStyle();
        googleMapRef.current?.data.forEach((item) => {
          if (item.getProperty("geojsonType") === "high-detail") {
            googleMapRef.current?.data?.remove(item);
          }
        });
        if (googleStringMapTypeRef.current === stringTypes.CADASTRAL) {
          googleMapRef.current?.data.addGeoJson(geoJson);
        }
      },
    }
  );

  /** geometries */
  const { mutate: getGeometries } = useMutation(requestGeometries, {
    onSuccess: (data) => {
      const { features } = data;
      const geoJson = {
        type: "FeatureCollection",
        features,
      };
      googleMapRef.current?.data.revertStyle();
      googleMapRef.current?.data.forEach((item) => {
        if (item.getProperty("geojsonType") === "geometries-all") {
          googleMapRef.current?.data?.remove(item);
        }
      });
      if (googleStringMapTypeRef.current === stringTypes.CADASTRAL) {
        googleMapRef.current?.data.addGeoJson(geoJson);
      }
    },
  });

  /** 항공 지도 마커 */
  const { mutate: getAirLineMarkers } = useMutation(requestAirLineMarkers, {
    onSuccess: (data) => {
      setAirlineMarkers(data.rows);
    },
  });

  const onLoad = (map: google.maps.Map) => {
    map.setCenter({
      lat: mapLocationInfo?.lat || 37.74385833,
      lng: mapLocationInfo?.lng || 126.49,
    });
    map.data.setStyle((feature) => {
      const color = getGeoJSONStrokeColor(feature.getProperty("jimok"));

      if (feature.getProperty("geojsonType") === "geometries-all") {
        return {
          fillColor: "rgba(0,0,0,0)",
          strokeColor: "#000",
          strokeWeight: 2,
          fillOpacity: 0.6,
          // strokeOpacity: 1,
          zIndex: -1,
        };
      } else if (feature.getProperty("geojsonType") === "high-detail") {
        return {
          fillColor: color,
          strokeColor: color,
          strokeWeight: 1,
          fillOpacity: 0.6,
          icon: undefined,
          zIndex: 10,
        };
      } else {
        return {
          fillColor: "#000",
          strokeColor: "#000",
          strokeWeight: 1,
          fillOpacity: 0.6,
          icon: undefined,
          zIndex: 5,
        };
      }
    });

    map.addListener(
      "zoom_changed",
      debounce(() => {
        const zoom = map.getZoom();
        if (typeof zoom === "number") {
          setGoogleMapZoom(zoom);
          googleMapZoomRef.current = zoom;
        }
      }, 1000)
    );

    map.addListener(
      "center_changed",
      debounce(() => {
        onCenterChanged();
      }, 500)
    );
    map.data.addListener("click", (event: any) => {
      mapAddClickListener(event, map);
    });
    map.data.addListener("mouseover", (event: any) => {
      const { feature } = event;

      if (
        feature.getProperty("geojsonType") === "high-detail" ||
        feature.getProperty("geojsonType") === "geometries-all"
      ) {
        if (!tooltipRef?.current?.visibleFix)
          tooltipRef?.current?.handleOpen(feature);
        googleMapRef.current?.data.overrideStyle(feature, {
          fillOpacity: 1,
        });
      } else {
        googleMapRef.current?.data.overrideStyle(feature, {
          fillOpacity: 6,
        });
      }
    });
    map.data.addListener("mouseout", (event: any) => {
      const { feature } = event;
      if (!tooltipRef?.current?.visibleFix) tooltipRef?.current?.handleClose();
      if (
        tooltipRef?.current?.data?.getProperty("id") !==
          feature.getProperty("id") ||
        !tooltipRef?.current?.visibleFix
      ) {
        if (feature.getProperty("geojsonType") === "high-detail") {
          googleMapRef.current?.data.revertStyle(feature);
        }
      }
    });

    const imageMapType = new google.maps.ImageMapType({
      getTileUrl: (coord, zoom) => {
        if (googleStringMapTypeRef.current !== stringTypes.AIRLINE) return "";
        const ymax = 1 << zoom;
        const y = ymax - coord.y - 1;
        return `https://d2hs5sgo9f85cf.cloudfront.net/high-res-tiles/${zoom}/${coord.x}/${y}.png`;
      },
      // tileSize: new google.maps.Size(256, 256),
    });

    map.overlayMapTypes.push(imageMapType);
    googleMapRef.current = map;
  };

  /** 문화자산 정보 내 슬라이드 이미지 클릭 시 이미지 모달 표기 */
  const [isImageModalVisible, setIsImageModalVisible] =
    useState<boolean>(false);
  const [currentImage, setCurrentImage] = useState<string>();
  const [nowImageIndex, setNowImageIndex] = useState<number>(0);
  const [nodes, setNodes] = useState<DataSet<Node, "id">>();
  const [edges, setEdges] = useState<DataSet<Edge, "id">>();

  /** 문화유산 메인 이미지 변경 함수 */
  const handleChangeImage = (url: string, index: number) => {
    return () => {
      setCurrentImage(url);
      setNowImageIndex(index);
    };
  };

  const handleClickImage = () => {
    if (currentImage) {
      setIsImageModalVisible(true);
    }
  };

  const handleAirLineMarkerClick = (item: IAirLineMarkersResponse) => {
    googleMapRef.current?.setCenter({
      lat: Number(item.lat),
      lng: Number(item.lng),
    });
    googleMapRef.current?.setZoom(18);
  };

  const handleChangeGoogleMapType = (
    type: google.maps.MapTypeId,
    stringMapType: string
  ) => {
    setGoogleMapType(type);
    setGoogleStringMapType(stringMapType);
    googleStringMapTypeRef.current = stringMapType;
  };

  /** 슬라이드 썸네일 이미지 */
  const renderHeritageImage = (param: number) => {
    if (cultureDetail)
      return (
        <HeritageImage onClick={() => {}} src={cultureDetail?.titleImage} />
      );
  };

  /** 문화자산 상세 정보 Information Rendering */
  const renderHeritageInfo = (param: number) => {
    return (
      <ContentLayout>
        <HeritageName>
          {/* 강화온수리성공회성당 (江華溫水里聖公會聖堂) */}
          {cultureDetail?.label || cultureDetail?.withoutUriId}{" "}
          {cultureDetail?.cnName && `(${cultureDetail.cnName})`}
        </HeritageName>
        {/** 추후 영어 이름 내려올 떄만 보여줄 수 있도록 변경 */}
        {cultureDetail?.enName && (
          <HeritageName>{cultureDetail?.enName}</HeritageName>
        )}
        <HeritageInfo>
          <p>{cultureDetail?.description}</p>
        </HeritageInfo>
        <HeritageImageListWrap>
          <HeritageImagesWrap>
            <SelectedImageWrap
              onClick={() => {
                handleClickImage();
              }}
            >
              <img
                width={"100%"}
                src={
                  currentImage ||
                  getImage(
                    cultureDetail?.protoTypeName ||
                      cultureDetail?.typeWithoutUri
                  )
                }
                alt={"slide-main-img"}
              />
            </SelectedImageWrap>
            {cultureDetail?.images && cultureDetail?.images.length > 0 && (
              <ImageSlideWrap>
                <ImageSlide slideWidth={520} imageWidth={68}>
                  {cultureDetail?.images?.map((item, idx) => (
                    <ImageWrap key={idx.toString()}>
                      <SlideImage
                        onClick={handleChangeImage(item, idx)}
                        src={item}
                        height="100%"
                      />
                    </ImageWrap>
                  ))}
                </ImageSlide>
              </ImageSlideWrap>
            )}
            <ActionButtonWrap>
              <ActionButtonList>
                {cultureDetail?.vr2D ? (
                  <LinkIcon
                    // href={"https://onsurianglicanchurch.netlify.app"}
                    href={cultureDetail?.vr2D}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img width={"100%"} alt="vr2d-on" src={IconVr2DOn} />
                  </LinkIcon>
                ) : (
                  <LinkIcon>
                    <img width={"100%"} alt="vr2d-off" src={IconVr2DOff} />
                  </LinkIcon>
                )}
                {cultureDetail?.vr3D ? (
                  <LinkIcon
                    // href={"https://onsurianglicanchurch.netlify.app"}
                    href={cultureDetail?.vr3D}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img width={"100%"} alt="vr3d-on" src={IconVr3DOn} />
                  </LinkIcon>
                ) : (
                  <LinkIcon>
                    <img width={"100%"} alt="vr3d-off" src={IconVr3DOff} />
                  </LinkIcon>
                )}
                {cultureDetail?.model3D ? (
                  <LinkIcon
                    href={cultureDetail?.model3D}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img width={"100%"} alt="model3d-on" src={IconModel3DOn} />
                  </LinkIcon>
                ) : (
                  <LinkIcon>
                    <img
                      width={"100%"}
                      alt="model3d-off"
                      src={IconModel3DOff}
                    />
                  </LinkIcon>
                )}
                {/*{cultureDetail?.cad ? (*/}
                {/*  <LinkIcon*/}
                {/*    href={cultureDetail?.cad}*/}
                {/*    target="_blank"*/}
                {/*    rel="noopener noreferrer"*/}
                {/*  >*/}
                {/*    <img width={"100%"} alt="cad-on" src={IconCadOn} />*/}
                {/*  </LinkIcon>*/}
                {/*) : (*/}
                {/*  <LinkIcon>*/}
                {/*    <img width={"100%"} alt="cad-off" src={IconCadOff} />*/}
                {/*  </LinkIcon>*/}
                {/*)}*/}
                {cultureDetail?.imageHiresUrl ? (
                  <LinkIcon
                    href={cultureDetail?.imageHiresUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img width={"100%"} alt="hires-on" src={IconImageHiresOn} />
                  </LinkIcon>
                ) : (
                  <LinkIcon>
                    <img
                      width={"100%"}
                      alt="hires-off"
                      src={IconImageHiresOff}
                    />
                  </LinkIcon>
                )}
                {cultureDetail?.pcd ? (
                  <LinkIcon
                    href={cultureDetail?.pcd}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img width={"100%"} alt="pcd-on" src={IconPcdOn} />
                  </LinkIcon>
                ) : (
                  <LinkIcon>
                    <img width={"100%"} alt="pcd-off" src={IconPcdOff} />
                  </LinkIcon>
                )}
              </ActionButtonList>
              <div>
                <img src={AccessImage} alt="접근성" width={165} height={52} />
              </div>
            </ActionButtonWrap>
          </HeritageImagesWrap>
          <HeritageImageInfoWrap>
            {cultureDetailInfo && <InfoLabelGroup items={cultureDetailInfo} />}
          </HeritageImageInfoWrap>
        </HeritageImageListWrap>
      </ContentLayout>
    );
  };

  const onCenterChanged = useCallback(() => {
    const center = googleMapRef.current?.getCenter();
    const lat = center?.lat();
    const lng = center?.lng();

    if (lat && lng) {
      setCurrentAllLocation((prev) => {
        if (prev) {
          const dist = getDistance(prev.lat, prev.lng, lat, lng);
          if (googleMapZoomRef.current) {
            if (googleMapZoomRef.current < 8 && dist < 400000) {
              return prev;
            } else if (googleMapZoomRef.current < 10 && dist < 1200000) {
              return prev;
            } else if (dist < 4000) {
              return prev;
            } else {
              return { lat, lng };
            }
          } else {
            return { lat, lng };
          }
        }
        return { lat, lng };
      });

      setCurrentLocation((prev) => {
        if (prev) {
          const dist = getDistance(prev.lat, prev.lng, lat, lng);
          if (dist < 4000) {
            return prev;
          } else {
            return { lat, lng };
          }
        } else {
          return { lat, lng };
        }
      });
    }
  }, [googleMapZoom]);

  const mapAddClickListener = useCallback(
    (event: any, map: google.maps.Map) => {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();

      const { feature } = event;
      if (
        feature.getProperty("geojsonType") === "high-detail" ||
        feature.getProperty("geojsonType") === "geometries-all"
      ) {
        // setFeatureState((prev: any) => {
        if (tooltipRef?.current?.visibleFix) {
          if (
            tooltipRef?.current?.data?.getProperty("id") ===
            feature.getProperty("id")
          ) {
            tooltipRef?.current?.handleVisibleFix();
            tooltipRef?.current?.handleClose();
          } else {
            map?.data.revertStyle();
            map?.data.overrideStyle(feature, {
              fillOpacity: 1,
            });
            tooltipRef?.current?.handleOpen(feature);
          }
        } else {
          map?.data.overrideStyle(feature, {
            fillOpacity: 1,
          });
          tooltipRef?.current?.handleOpen(feature);
          if (!tooltipRef?.current?.visibleFix) {
            tooltipRef?.current?.handleVisibleFix();
          }
        }
      }

      if (feature.getProperty("geojsonType") !== "geometries-all") {
        map?.setCenter({ lat, lng });

        if (lat && lng) {
          onCenterChanged();
        }
        if ((map?.getZoom() || 0) < 15) {
          map?.setZoom(15);
        }
      }
    },
    [onCenterChanged]
  );

  useEffect(() => {
    if (googleMapStringType === stringTypes.CADASTRAL) {
      if (googleMapZoom >= 13) {
        getGeometries({
          distance: 4000,
          lat: currentAllLocation?.lat,
          lng: currentAllLocation?.lng,
          type: "myeon",
        });
      } else if (googleMapZoom >= 9) {
        getGeometries({
          distance: 400000,
          lat: currentAllLocation?.lat,
          lng: currentAllLocation?.lng,
          type: "gun",
        });
      } else {
        getGeometries({
          distance: 1200000,
          lat: currentAllLocation?.lat,
          lng: currentAllLocation?.lng,
          type: "do",
        });
      }
    } else {
    }
  }, [googleMapStringType, getGeometries, currentAllLocation, googleMapZoom]);

  useEffect(() => {
    if (googleMapStringType === stringTypes.CADASTRAL) {
      if (googleMapZoom < 13) {
        getUnionGeometries();
        googleMapRef.current?.data.forEach((item) => {
          if (item.getProperty("geojsonType") === "high-detail") {
            googleMapRef.current?.data.remove(item);
          }
        });
      }
      if (googleMapZoom >= 13) {
        getHighDetailGeometries({
          distance: 4000,
          lat: currentLocation?.lat,
          lng: currentLocation?.lng,
        });
        googleMapRef.current?.data.forEach((item) => {
          if (item.getProperty("geojsonType") === "union") {
            googleMapRef.current?.data.remove(item);
          }
        });
      }
    } else {
    }
  }, [
    googleMapStringType,
    getUnionGeometries,
    googleMapZoom,
    getHighDetailGeometries,
    currentLocation,
  ]);

  const markersVisiblesRef = useRef<boolean[]>([]);
  const [_, setMarkersVisible] = useState<boolean>(false);

  useEffect(() => {
    if (googleMapStringType !== stringTypes.CADASTRAL) {
      googleMapRef.current?.data.forEach((feature) => {
        googleMapRef.current?.data.remove(feature);
      });
    }
    if (googleMapStringType === stringTypes.AIRLINE) {
      getAirLineMarkers();
    } else {
      setAirlineMarkers([]);
    }
  }, [googleMapStringType]);

  // useEffect(() => {
  //   if (isLoaded && googleMapRef.current) {
  //     googleMapRef.current.setCenter({
  //       lat: mapLocationInfo?.lat || 37.74385833,
  //       lng: mapLocationInfo?.lng || 126.49,
  //     });
  //   }
  // }, [isLoaded, mapLocationInfo]);

  return (
    <Layout selectedMenu="heritage" onChangePage={handleChangePage}>
      <ImageModal
        param={Number(id)}
        visible={isImageModalVisible}
        onClose={() => {
          setIsImageModalVisible(false);
        }}
        images={cultureDetail?.images || []}
        nowIndex={nowImageIndex}
      />
      {cultureDetail?.titleImage && (
        <HeritageImageWrap>{renderHeritageImage(Number(id))}</HeritageImageWrap>
      )}
      <HeritageDetailWrap>
        {renderHeritageInfo(Number(id))}
        {/**
         * satellite 위성, 항공
         * terrain 일반
         * hybrid 지적도
         */}
        <MapWrap>
          <GoogleMapTypeController
            currentType={googleMapStringType}
            onChange={handleChangeGoogleMapType}
          />
          <CustomGoogleMap
            isLoaded={isLoaded}
            mapContainerStyle={{
              width: 1120,
              height: 630,
            }}
            options={{
              mapTypeControl: false,
              streetViewControl: false,
              minZoom: 8,
              restriction: {
                latLngBounds: {
                  north: 38.634,
                  south: 33.0041,
                  east: 131.5775,
                  west: 124.2656,
                },
              },
            }}
            mapTypeId={googleMapType}
            onLoad={onLoad}
            onUnmount={onUnmount}
            zoom={10}
          >
            {mapLocationInfo && (
              <Marker
                position={{
                  lat: Number(mapLocationInfo.lat),
                  lng: Number(mapLocationInfo.lng),
                }}
                key={"main-marker"}
              />
            )}
            {relatedList.map((item) => {
              if (item.lat && item.lng) {
                return (
                  <MarkerF
                    position={{
                      lat: Number(item.lat),
                      lng: Number(item.lng),
                    }}
                    key={item.id}
                  />
                );
              }
              return null;
            })}
            {airlineMarkers?.map((item, idx) => (
              <MarkerF
                key={idx.toString()}
                icon={{
                  url: "/img/icon/AerialMap.svg",
                  scaledSize: new window.google.maps.Size(32, 32),
                }}
                onClick={() => handleAirLineMarkerClick(item)}
                onMouseOver={() => {
                  markersVisiblesRef.current[idx] = true;
                  setMarkersVisible((prev) => !prev);
                }}
                onMouseOut={() => {
                  markersVisiblesRef.current[idx] = false;
                  setMarkersVisible((prev) => !prev);
                }}
                position={{
                  lat: Number(item.lat),
                  lng: Number(item.lng),
                }}
                onLoad={(marker) => {
                  markersVisiblesRef.current.push(false);
                }}
              >
                {markersVisiblesRef.current[idx] && (
                  <InfoWindowF>
                    <div style={{ fontSize: "14px" }} className="overlay">
                      <div>이름 : {nullCheck(item.label)}</div>
                      {/* <div>구분 : {nullCheck(info.typeName)}</div>
      <div>분류 : {nullCheck(info.classificationTypeName)}</div>
      <div>소재지 : {nullCheck(info.place)}</div> */}
                    </div>
                  </InfoWindowF>
                )}
              </MarkerF>
            ))}
          </CustomGoogleMap>
          <MapTooltip ref={tooltipRef} />
        </MapWrap>
        {relatedList?.length > 0 && (
          <HeritageSlideWrap>
            <ContentLayout>
              <SlideWidthHeader
                title="관련 자료"
                imageWidth={274}
                slideWidth={1060}
                gap={30}
              >
                {relatedList?.map((item) => (
                  <SlideHeritageCard key={item.id} data={item} />
                ))}
              </SlideWidthHeader>
            </ContentLayout>
          </HeritageSlideWrap>
        )}
        <CytosCapeWrap>
          <ContentLayout>
            <SubTitle>관계 분석 결과</SubTitle>
            <NGraph nodes={nodes} edges={edges} />
          </ContentLayout>
        </CytosCapeWrap>
        <WordCloudWithInfo data={wordCloudData} />
      </HeritageDetailWrap>
    </Layout>
  );
};
export default HeritageDetail;

const HeritageImageWrap = styled.div`
  height: 522px;
  position: relative;
  margin-top: 80px;
  overflow: hidden;
`;
const HeritageImage = styled.div<{ src: string }>`
  height: 100%;
  background-attachment: fixed;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-repeat: no-repeat;
`;
export const HeritageDetailWrap = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 120px 80px 186px 80px;
`;

const HeritageName = styled.h2`
  ${fonts("H2")};
`;

const HeritageInfo = styled.div`
  margin-top: 32px;
  ${fonts("B3")};
  color: ${colors.GRAY5};
`;
const MapWrap = styled.div`
  position: relative;
  width: 1120px;
  height: 630px;
  margin-top: 40px;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  // overflow: hidden;
`;

const HeritageImageListWrap = styled.div`
  display: flex;
  gap: 0 20px;
  margin-top: 40px;
`;
const HeritageImagesWrap = styled.div`
  flex: 1;
`;
const ImageSlideWrap = styled.div`
  margin-top: 20px;
`;
const HeritageImageInfoWrap = styled.div`
  flex: 1;
`;

const SelectedImageWrap = styled.div`
  width: 520px;
  position: relative;
  height: 374px;
  border-radius: 16px;
  overflow: hidden;
  background-color: ${colors.GRAY9};
  > img {
    cursor: pointer;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
const ActionButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;
const ActionButtonList = styled.div`
  display: flex;
  gap: 0 8px;
`;
const SubTitle = styled.h3`
  ${fonts("H3")};
`;
const ImageWrap = styled.div`
  position: relative;
  width: 68px;
  height: 68px;
  overflow: hidden;
  cursor: pointer;
`;
const SlideImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const HeritageSlideWrap = styled.div`
  margin-top: 40px;
  margin-bottom: 5px;
`;
const CytosCapeWrap = styled.div`
  margin-top: 40px;
`;
const LinkIcon = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
`;
